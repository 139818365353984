import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense, lazy, useState, useEffect } from "react";
import MainLayout from "./components/MainLayout";
import Loader from "./components/Loader";
import imgb from "./assets/logo.jpg"
const BlogList = lazy(() => import("./pages/Bloglist"));
const BlogCreate = lazy(() => import("./pages/BlogCreate"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Orders = lazy(() => import("./pages/Orders"));
const Customers = lazy(() => import("./pages/Customers"));
const Categorylist = lazy(() => import("./pages/Categorylist"));
const ProductCreate = lazy(() => import("./pages/ProductCreate"));
const Productlist = lazy(() => import("./pages/Productlist"));
const Addcat = lazy(() => import("./pages/Addcat"));
const ViewOrder = lazy(() => import("./pages/ViewOrder"));
const Taglist = lazy(() => import("./pages/taglist"));
const AddTag = lazy(() => import("./pages/AddTag"));
const AdminSubscriptions = lazy(() => import("./pages/AdminSubscriptions"));

function App() {
  // State to manage loader visibility
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the loader after 3 seconds (3000ms)
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500); // Delay in milliseconds

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, []);

  const AuthRoute = ({ children }) => {
    const authData = localStorage.getItem("auth");

    //   console.log("Auth Data from LocalStorage:", authData); // Debugging output

    if (!authData) {
      console.log("No auth data found, redirecting to login");
      return <Navigate to="/" replace />;
    }

    try {
      const parsedAuthData = JSON.parse(authData);
      const accessToken = parsedAuthData?.access_token;

      // console.log("Parsed Access Token:", accessToken); // Debugging output

      if (!accessToken) {
        console.log("No access token found, redirecting to login");
        return <Navigate to="/" replace />;
      }

      // Here you might also want to validate the token if needed
      // e.g., check its expiration date or other conditions
      return children;
    } catch (error) {
      console.error("Error parsing auth data:", error);
      return <Navigate to="/" replace />;
    }
  };


  return (
    <Router>
      {showLoader && <Loader />}
      {!showLoader && (
        <Suspense fallback={<Loader />} >
          <Routes>
            {/* Open Route */}
            <Route path="/" element={<Login />} />

            {/* Protected Routes */}
            <Route
              path="/admin"
              element={
                <AuthRoute>
                  <MainLayout />
                </AuthRoute>
              }
            >
              <Route index element={<Dashboard />} />
              <Route path="blog-list" element={<BlogList />} />
              <Route path="blogcreate" element={<BlogCreate />} />
              <Route path="blog" element={<BlogCreate />} />
              <Route path="orders" element={<Orders />} />
              <Route path="order/:id" element={<ViewOrder />} />
              <Route path="customers" element={<Customers />} />
              <Route path="list-category" element={<Categorylist />} />
              <Route path="category" element={<Addcat />} />
              <Route path="category/:id" element={<Addcat />} />
              <Route path="tags" element={<AddTag />} />
              <Route path="tag/:id" element={<AddTag />} />
              <Route path="list-tag" element={<Taglist />} />
              <Route path="list-product" element={<Productlist />} />
              <Route path="product" element={<ProductCreate />} />
              <Route path="/admin/edit-product/:id" element={<ProductCreate />} />
              <Route path="product/:id" element={<ProductCreate />} />
              <Route path="subscriptions" element={<AdminSubscriptions />} />
            </Route>
          </Routes>
        </Suspense>
      )}
    </Router>
  );
}

export default App;
